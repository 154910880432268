












































































import { Component } from 'vue-property-decorator';
import Project from '../models/Project';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import AuditService from '@/services/AuditService';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component
export default class AuditLoggingModal extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project): boolean {
    const permission = hasPermission(action, project);
    return permission != null && permission;
  }

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Audit Logging', active: true },
  ];

  protected startDate = '';
  protected startTime = '';
  protected endDate = '';
  protected endTime = '';
  protected errorMsg = '';

  protected auditData: string | null = null;

  mounted(): void {
    if (!hasPermission(PossibleAction.CAN_NAVIGATE_AUDIT)) {
      this.$router.push({ path: '/' });
    }
  }

  protected loadAuditLogging(): void {
    if (hasPermission(PossibleAction.CAN_GET_AUDIT)) {
      this.auditData = null;
      const start = this.getStartDate();
      const end = this.getEndDate();
      if (start && end) {
        if (start.getTime() > end.getTime()) {
          this.errorMsg = 'End time has to be after start time.';
          return;
        }
      }
      this.setLoading(true);
      AuditService.getLogs(this.getStartDate(), this.getEndDate())
        .then((logs) => {
          this.auditData = JSON.stringify(logs.data);
          this.showToast('Successfully loaded', 'Audit Logging data was successfully loaded.', 'success');
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to loaded', 'Could not load logging data.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  // https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
  protected exportAsFile(): void {
    if (hasPermission(PossibleAction.CAN_GET_AUDIT) && this.auditData) {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.auditData));
      element.setAttribute('download', 'audit-logging-' + moment(new Date(Date.now())).format('YYYY-MM-DD') + '.json');

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  protected resetFilter(): void {
    this.startDate = '';
    this.startTime = '';
    this.endDate = '';
    this.endTime = '';
  }

  protected getStartDate(): Date | undefined {
    if (this.startDate.length === 0) {
      return undefined;
    } else if (this.startTime.length === 0) {
      this.startTime = '00:00';
    }
    const start = new Date(this.startDate + ' ' + this.startTime);
    return new Date(start.getTime() - start.getTimezoneOffset() * 60000);
  }

  protected getEndDate(): Date | undefined {
    if (this.endDate.length === 0) {
      return undefined;
    } else if (this.endTime.length === 0) {
      this.endTime = '23:59';
    }
    const end = new Date(this.endDate + ' ' + this.endTime);
    return new Date(end.getTime() - end.getTimezoneOffset() * 60000);
  }

  protected minDate(): Date | null {
    return this.startDate.length === 0 ? new Date(0, 1, 1) : this.getStartDate() ?? null;
  }

  protected maxDate(): Date | null {
    return this.endDate.length === 0 ? new Date(3000, 12, 31) : this.getEndDate() ?? null;
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
