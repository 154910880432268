import MainService from '@/services/MainService';
import { AxiosResponse } from 'axios';

export default class AuditService {
  private static mainURL = '/audit';

  public static getLogs(start?: Date, end?: Date): Promise<AxiosResponse> {
    return MainService.get(this.mainURL, { params: { start, end } });
  }
}
